import React from 'react'

// Third Party
import styled from 'styled-components'

// Components
import ButtonWhite from 'components/elements/ButtonWhite'
import Image from 'components/shared/ImageGatsby'
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'

interface BannerSmallProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerSmall = styled.section`
  height: 430px;
  margin-top: 18rem !important;
  @media (max-width: 767px) {
    height: 300px;
  }

  & .my-5 {
    margin-top: -4rem !important;
  }
  & .justify-content-end {
    margin-top: 11px !important;
    @media (max-width: 991px) {
      margin-top: 3px !important;
    }
  }
`

const StyledImage = styled(Image)`
  @media (min-width: 991px) {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
  }

  min-height: 430px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @media (max-width: 1199px) {
    & img {
      object-fit: cover !important;
    }
  }

  @media (max-width: 767px) {
    min-height: 300px;
  }
`

const StyledTitleDefault = styled(TitleDefault)``

const StyledButtonDefault = styled(ButtonWhite)`
  & > a {
    padding: 20px 15px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 20px;
    color: black;
  }
`

const BannerSmall: React.FC<BannerSmallProps> = ({ fields }) => (
  <StyledBannerSmall className={`${fields?.hideOnMobile === true ? 'd-none' : 'd-lg-flex'} container position-relative align-items-start mb-5`}>
    <StyledImage
      alt="Lofthus"
      image={fields.image}
      className="position-absolute"
      loading="lazy"
    />
    <div className="container d-flex flex-column align-items-start justify-content-center position-relative h-100">
      <StyledTitleDefault className="position-relative">
        <Content content={fields.description} />
      </StyledTitleDefault>

      <div className="row w-100 h-100 justify-content-center align-items-center">
        <div className="col-md-8 col-12">
          <StyledButtonDefault
            isAnchor
            to={fields?.link?.url || ''}
            className="text-center mb-5"
          >
            {fields?.link?.title}
          </StyledButtonDefault>
        </div>
      </div>
    </div>
  </StyledBannerSmall>
)

export default BannerSmall
