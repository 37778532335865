import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import Image from 'components/shared/ImageGatsby'

// SVG
import ArrowRight from 'img/arrow-right.svg'

const StyledImage = styled(Image)`
  @media (min-width: 992px) {
    height: 600px;
  }
  @media (max-width: 767px) {
    min-height: 200px;
  }
`

const Wrapper = styled.section`
  @media (min-width: 992px) {
    margin-bottom: 10rem;
  }
`

const Content = styled(ParseContent)`
  position: absolute;
  z-index: 2;
  left: -310px;
  top: 160px;
  min-width: 500px;
  min-height: 300px;
  max-width: 500px;
  background-color: ${(props) => props.theme.color.face.main};
  border: 1px solid ${(props) => props.theme.color.text.secondary};

  & img {
    width: 60px;
    height: 60px;
    opacity: 0.2;
    @media (max-width: 991px) {
      max-width: 40px;
      max-height: 40px;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size['21']};
    color: ${(props) => props.theme.color.text.dark};
    line-height: 30px;
  }

  & a {
    color: ${(props) => props.theme.color.text.dark};

    &:after {
      transition: all 0.2s ease-in-out;
      content: url(${ArrowRight});
      padding-left: 10px;
    }

    &:hover:after {
      padding-left: 20px;
    }
  }
  @media (min-width: 2000px) {
    left: -230px;
  }

  @media (max-width: 991px) {
    position: relative;
    top: 0;
    left: 0;
    min-width: 213px;
    max-width: 100%;
  }

  @media (max-width: 575px) {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
  }
`

const Row = styled.div`
  @media (min-width: 2000px) {
    justify-content: center !important;
  }
`

const Column = styled.div`
  max-width: 1120px;

  @media (min-width: 991px){
    min-height: 600px;
  }
`

interface BannerRightProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImage
}

const BannerRight: React.FC<BannerRightProps> = ({ fields }) => (
  <Wrapper className="mt-4 pb-4 pb-lg-0">
    <div className="container-fluid p-0">
      <Row className="row justify-content-end">
        <Column className="col-lg-8 col-12 position-relative">
          <div className="d-flex justify-content-center">
            <StyledImage
              image={fields.image}
              className="position-relative position-lg-absolute"
              alt="Lofthus"
              loading="lazy"
            />
          </div>

          {fields?.description && (
            <Content className="p-lg-5 p-4" content={fields.description} />
          )}
        </Column>
      </Row>
    </div>
  </Wrapper>
)

export default BannerRight
