import React from 'react'

// Components
import BannerDefault from 'components/flex/Banner/BannerDefault'
import BannerSmall from 'components/flex/Banner/BannerSmall'
import BannerSlider from 'components/flex/Banner/BannerSlider'
import BannerRight from 'components/flex/Banner/BannerRight'

interface BannerShellProps {
  fields: any
  location?: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerShellProps> = ({ fields, location = {} }) => {
  const banners: BannersProps = {
    default: BannerDefault,
    small: BannerSmall,
    slider: BannerSlider,
    right: BannerRight,
  }

  if (!fields.styleType || !banners[fields.styleType]) {
    return null
  }

  const Component = banners[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerShell
